<template>
  <div>
    <vx-card
      title="إرسال إشعار"
      subtitle="إرسال إشعار لكل مستخدمي النظام"
      title-icon="BellIcon"
      title-color="primary"
    >
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <div class="title vx-row m-2">
            <label class="text-lg mt-5 mb-4">عنوان الإشعار</label>
            <vs-input
              v-validate="'required|min:0|max:65'"
              class="w-full"
              name="title"
              data-vv-as="عنوان الإشعار"
              v-model="notification.title"
              :class="notification.title.length > 65 ? 'text-danger' : ''"
            />
            <vs-chip
              class="mt-2 text-xs"
              :color="notification.title.length > 65 ? 'danger' : 'primary'"
              >{{ notification.title.length }} / 65</vs-chip
            >
            <span class="text-danger text-sm" v-show="errors.has('title')">
              {{ errors.first("title") }}
            </span>
          </div>
          <div class="desc mt-5 vx-row m-2">
            <label class="vs-input--label text-lg  mb-4">وصف الإشعار</label>
            <vs-textarea
              counter="240"
              name="desc"
              class="w-full"
              v-model="notification.body"
              v-validate="'required|max:240'"
              data-vv-as="وصف الإشعار"
            />
            <span class="text-danger text-sm" v-show="errors.has('desc')">
              {{ errors.first("desc") }}
            </span>
          </div>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-10 seperator">
          <div class="vx-row">
            <div class="w-1/3 m-5 mt-0">
              <label for="type">نوع الإشعار</label>
              <v-select
                label="type"
                v-validate="'required'"
                name="type-of-banner"
                data-vv-as="نوع الإعلان"
                :options="banner_types"
                v-model="select_type"
                @input="exe(select_type)"
                class="w-full"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('type-of-banner')"
                >{{ errors.first("type-of-banner") }}</span
              >
            </div>
            <div
              v-if="banner_types[3] != select_type && select_type"
              class="w-1/2 m-5 mt-0"
            >
              <label for="type">{{ select_type }}</label>
              <v-select
                :label="checkType()"
                v-validate="'required'"
                data-vv-as="الإختيار"
                name="selected-value"
                :options="listSelection"
                v-model="notification.data_id"
                :reduce="(item) => item.id"
                class="w-full"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('selected-value')"
                >{{ errors.first("selected-value") }}</span
              >
            </div>
          </div>
          <div class="vx-row w-full" v-if="getItemInSelection()">
            <div class="vx-col w-full mt-24">
              <h5 class="text-primary text-2xl">
                <vs-icon icon="local_offer" class="text-lg"></vs-icon>إسم المنتج
                المُختار:
              </h5>
              <h6 class="mt-4">{{ getItemInSelection() }}</h6>
            </div>
          </div>
        </div>
        <div
          class="
            vx-row
            w-full
            text-center
            justify-items-center justify-center
            mt-10
          "
        >
          <vs-button
            @click="sendGlobalNotif"
            class="text-lg mt-5 mb-5"
            dir="ltr"
            type="filled"
            icon="send"
          >
            إرسال الإشعار
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
// 240 desc  65 title
import vSelect from "vue-select";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      listSelection: [],
      notification: {
        title: "",
        body: "",
        type: "",
        data_id: "",
      },
      banner_types: ["علامة تجارية", "منتج", "متجر", "تجاري"],
      select_type: "",
    };
  },
  components: {
    vSelect,
  },
  methods: {
    ...mapActions("products", ["fetchBrands"]), //getting brands
    ...mapActions("banners", ["productSKUs", "createBanner"]),
    ...mapActions("stores", ["fetchStores"]),
    ...mapActions("NotifManager", ["sendGlobalNotification"]),

    exe(type) {
      // this.callDataType(type);
      this.callDataType(type);
      switch (type) {
        case "علامة تجارية":
          this.notification.type = "brand";

          return "name";
        case "منتج":
          this.notification.type = "product";

          return "sku";
        case "متجر":
          this.notification.type = "store";

          return "name";
        case "تجاري":
          this.notification.type = "not-clickable";

          return "null";
      }
    },
    getItemInSelection() {
      var item = "";
      this.listSelection.find((x) => {
        if (x.id == this.notification.data_id) {
          item = x.title;
        }
      });
      return item;
    },
    sendGlobalNotif() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("title", this.notification.title);
          bodyFormData.set("body", this.notification.body);
          bodyFormData.set("type", this.notification.type);
          bodyFormData.set("data_id", this.notification.data_id ? this.notification.data_id : "999");

          this.sendGlobalNotification(bodyFormData)
            .then(() => {
              this.$vs.dialog({
                title: "تم بنجاح",
                text: "تم إرسال الإشعار لكل مستخدمين النظام.",
                color: "success",
                acceptText: "حسناً",
              });
              this.$router.push({
                name: "home",
              });
            })
            .catch((error) => {
              this.errorDialog(error);
            });
        }
      });
    },
    checkType() {
      switch (this.select_type) {
        case "علامة تجارية":
          return "name";
        case "منتج":
          return "sku";
        case "متجر":
          return "name";
        case "تجاري":
          return "null";
      }
    },
    clearSelection() {
      this.listSelection = [];
      this.notification.data_id = "";
    },
    callDataType(type) {
      switch (type) {
        case "علامة تجارية":
          this.clearSelection();
          this.fetchBrands().then((r) => {
            this.listSelection = r.data.data;
          });
          return "0";
        case "منتج":
          this.clearSelection();
          this.productSKUs().then((r) => {
            this.listSelection = r;
          });
          return "1";
        case "متجر":
          this.clearSelection();
          this.fetchStores().then((r) => {
            this.listSelection = r.data.data;
          });
          return "2";
        case "تجاري":
          this.clearSelection();
          return "3";
      }
    },
  },
};
</script>

<style scoped>
.seperator {
  border-left: 1px solid rgba(204, 204, 204, 0.4);
}
</style>
